import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import { useTransition, animated, config } from "react-spring"

// Styles
import GlobalStyles from "./home/components/global"

import mockup from "../images/nightlife/waitlist-new.png"

import friends from "../images/nightlife/friends-mockup.png"
import explore from "../images/nightlife/explore-mockup.png"
import messenger from "../images/messenger.png"

const slides = [
  { id: 0, path: require("../images/messages/1.png") },
  { id: 1, path: require("../images/messages/2.png") },
  { id: 2, path: require("../images/messages/3.png") },
  { id: 3, path: require("../images/messages/4.png") },
  { id: 4, path: require("../images/messages/5.png") },
  { id: 5, path: require("../images/messages/6.png") },
  { id: 6, path: require("../images/messages/7.png") },
  { id: 7, path: require("../images/messages/8.png") },
]

function Venteliste2Page() {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Waitlist #1")
    }
    if (window) {
      window.location.href = "#"
    }
  }, [])

  const clickedButton = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Messenger Waitlist Button #1")
    }
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("init", "215168280059405", {})
        window.fbq("track", "CompleteRegistration")
      }
    }
  }

  const clickedInfluencerButton = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Influencer Button")
    }
  }

  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { position: "absolute", top: 0, opacity: 0 },
    enter: { top: 40, opacity: 1 },
    leave: { top: 0, opacity: 0 },
    config: config.default,
  })
  useEffect(() => setInterval(() => set(state => (state + 1) % 8), 3000), [])

  const displayMessages = () => {
    return transitions.map(({ item, props, key }) => (
      <animated.div
        key={key}
        class="bg"
        style={{
          ...props,
          backgroundImage: `url(${item.path})`,
          width: "100%",
          height: "100%",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
    ))
  }

  return (
    <Container>
      <SEO title="Venteliste" />
      <GlobalStyles />
      <MessagesDiv>{displayMessages()}</MessagesDiv>
      <SectionOne>
        <PositioningDiv>
          <TextDiv>
            <QueueText>Queue</QueueText>
            <Seperator />
            <Catchline>
              <White>Se hvor</White> festen <White>er - hver</White> weekend👀
            </Catchline>
            <a
              onClick={() => clickedButton()}
              href="https://m.me/queueapp?ref=ml-vl-refCode--NpPm0F0"
            >
              <Button>
                <ButtonText>Join ventelisten</ButtonText>
                <MessengerDiv>
                  <MessengerIcon />
                </MessengerDiv>
              </Button>
            </a>
            <Emoji>👇</Emoji>

            <Desc>
              <Yellow>Queue</Yellow> app'en dropper i København start 2021 for
              de <Yellow>øverste</Yellow> på ventelisten og udvalgte{" "}
              <Yellow>influencers</Yellow>🤫
            </Desc>
          </TextDiv>
        </PositioningDiv>
      </SectionOne>
      <SectionTwo>
        <ImageSectionOne>
          <MockupImageOne />
          <TextBoxOne>
            <SeperatorBox />

            <ImageText>
              Følg med i <Yellow>hvor</Yellow> dine
              <Yellow> venner</Yellow> fester😍
            </ImageText>
          </TextBoxOne>
        </ImageSectionOne>
      </SectionTwo>
      <SectionTwo>
        <ImageSectionTwo>
          <MockupImageTwo />
          <TextBoxOne>
            <SeperatorBox />

            <ImageText>
              Se de <Yellow>barer</Yellow>, <Yellow>klubber</Yellow> og{" "}
              <Yellow>fester</Yellow>, der popper <Yellow>nær </Yellow>dig🍾
            </ImageText>
          </TextBoxOne>
        </ImageSectionTwo>
      </SectionTwo>

      <SectionTwoButton>
        <a
          onClick={() => clickedButton()}
          href="https://m.me/queueapp?ref=ml-vl-refCode--NpPm0F0"
        >
          <Button>
            <ButtonText>Join ventelisten</ButtonText>
            <MessengerDiv>
              <MessengerIcon />
            </MessengerDiv>
          </Button>
        </a>
      </SectionTwoButton>

      <SectionThree>
        <PositioningDiv>
          <TextDiv>
            <InfluencerHeading>Influencer fra København?</InfluencerHeading>
            <Seperator />

            <ThickHeading>
              Skip ventelisten og bliv <Yellow>verified</Yellow> på Queue med
              det samme, når <Yellow>betaen</Yellow> dropper🎉
            </ThickHeading>

            <a
              onClick={() => clickedInfluencerButton()}
              href="https://anker493456.typeform.com/to/iWHk8M6Z"
            >
              <Button>
                <ButtonText>Ansøg nu🤠</ButtonText>
              </Button>
            </a>
          </TextDiv>
        </PositioningDiv>
      </SectionThree>
      <SectionFour>
        <LinkInsta href="https://instagram.com/join_vibe">
          Følg med på Insta'en🤑
        </LinkInsta>
      </SectionFour>
    </Container>
  )
}

export default Venteliste2Page

const Container = styled.div`
  width: 100vw;
  height: 100%;
  flex: 1;
  max-width: 960px;
  margin: 0 auto;
  padding-right: 50px;
  padding-left: 50px;

  // color
  background-color: #121212;

  z-index: 1;

  * {
    margin: 0;
  }
`

const MessagesDiv = styled.div`
  width: 400px;
  height: 150px;
  max-width: 960px;
  margin: 0 auto;

  position: absolute;
  top: 0;

  @media screen and (max-width: 750px) {
    width: 280px;
    height: 80px;
  }

  @media screen and (max-width: 350px) {
    width: 250px;
    height: 70px;
  }
`

const SectionOne = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 90%;
  margin-top: 180px;
  margin-bottom: 25px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  z-index: 2;

  @media screen and (max-width: 750px) {
    margin-top: 140px;
  }
`

const PositioningDiv = styled.div`
  margin: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`

const SectionTwo = styled.div`
  margin-top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SectionTwoButton = styled.div`
  margin-top: 0px;
  margin-bottom: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    align-self: center;
  }
`

const SectionThree = styled.div`
  margin: 0 auto;

  margin-top: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: center;
`

const SectionFour = styled.div`
  margin: 0 auto;

  margin-top: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
`

const ImageSectionOne = styled.div`
  display: flex;
  max-width: 960px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ImageSectionTwo = styled.div`
  display: flex;
  max-width: 960px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const MockupImageOne = styled.img`
  content: url(${friends});
  flex: 1;
  width: 50%;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 600px) {
    width: 65%;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`

const MockupImageTwo = styled.img`
  content: url(${explore});
  flex: 1;
  width: 50%;
  height: 100%;
  object-fit: cover;
  order: 2;

  @media screen and (max-width: 600px) {
    width: 65%;
    order: 0;
  }
  @media screen and (max-width: 400px) {
    width: 80%;
  }
`

const SeperatorBox = styled.div`
  align-self: center;
  border-bottom: 4px solid #fdde68;
  width: 50px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 55px;
  }
`

const ImageText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 30px;
  margin-bottom: 20px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 1.5rem;
    text-align: center;

    margin-bottom: 30px;
  }
`

const TextBoxOne = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    max-width: 80%;
  }
`

const TextDiv = styled.div`
  margin: 0;
  align-self: left;
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Button = styled.div`
  z-index: 3;
  border: 4px solid #fdde68;
  width: 300px;
  height: 65px;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  @media screen and (max-width: 750px) {
    width: 240px;
    height: 50px;
  }

  @media screen and (max-width: 660px) {
    width: 210px;
    height: 50px;
  }
`

const ButtonText = styled.p`
  justify-content: center;
  margin-right: 5px;
  text-align: center;
  line-height: 57px;
  align-items: center;
  font-family: Arial Black, Arial Bold, Gadget, sans-serif;
  font-size: 23px;
  color: #fff;

  @media screen and (max-width: 750px) {
    font-size: 20px;
    line-height: 42px;
    margin-right: 3px;
  }

  @media screen and (max-width: 660px) {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 42px;
  }
`

const MessengerDiv = styled.div`
  justify-content: center;
  align-items: flex-start;
  margin-left: 5px;
  @media screen and (max-width: 750px) {
    margin-left: 3px;
  }
`

const MessengerIcon = styled.img`
  content: url(${messenger});

  max-width: 38px;
  height: 100%;
  object-fit: contain;
  @media screen and (max-width: 750px) {
    width: 30px;
  }
  @media screen and (max-width: 660px) {
    width: 25px;
  }
`

const Catchline = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 40px;

  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  margin-top: 15px;

  @media screen and (max-width: 750px) {
    font-size: 35px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`

const QueueText = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 40px;

  margin: 0;
  padding: 0;

  @media screen and (max-width: 750px) {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`

const InfluencerHeading = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;
  font-size: 35px;

  margin: 0;
  padding: 0;

  @media screen and (max-width: 750px) {
    font-size: 30px;
  }

  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`

const Seperator = styled.p`
  border-bottom: 4px solid #fdde68;
  width: 50px;
  margin-left: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    width: 55px;
  }
`

const Emoji = styled.p`
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 3px;
`

const Desc = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 28px;
  margin-bottom: 25px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 25px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 1.5rem;

    margin-bottom: 30px;
  }
`

const ThickHeading = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;
  line-height: 2rem;
  font-size: 30px;
  margin-bottom: 20px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;

    line-height: 1.7rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 1.5rem;

    margin-bottom: 30px;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const White = styled.span`
  color: #fff;
`

const LinkInsta = styled(props => <a {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 1.2rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fff;

  margin: 0;
  padding: 0;

  margin-bottom: 30px;

  // color

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;

    margin-bottom: 30px;
  }
`
